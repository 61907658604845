import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, image: metaImage, keywords, title, product, parentVariant, children }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        
        const image = metaImage && metaImage.src
            ? metaImage
            : null

        const isProduct = product;

        if (isProduct) {
          return (
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
  
              meta={[
                { 
                  name: `og:url`,
                  content: `https://www.coppercompression.com/products/${product.handle}`
                },
                { 
                  name: `og:image`,
                  content: `https://www.coppercompression.com/${product.images[0].localFile.childImageSharp.fluid.src}`
                },
                { 
                  name: `og:price:amount`,
                  content: product.priceRange.maxVariantPrice.amount
                },
                { 
                  name: `og:price:currency`,
                  content: `USD`
                },
                { 
                  name: `product:brand`,
                  content: `Copper Compression`
                },
                { 
                  name: `product:availability`,
                  content: 'in_stock'
                },
                { 
                  name: `availability`,
                  content: 'in_stock'
                },
                { 
                  name: `product:condition`,
                  content: `new`
                },
                { 
                  name: `product:price:amount`,
                  content: product.priceRange.maxVariantPrice.amount
                },
                { 
                  name: `product:price:currency`,
                  content: `USD`
                },
                {
                  name: `product:retailer_item_id`,
                  content: parentVariant
                },
                {
                  name: `description`,
                  content: metaDescription,
                },
                {
                  property: `og:title`,
                  content: title,
                },
                {
                  property: `og:description`,
                  content: metaDescription,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.author,
                },
                {
                  name: `twitter:title`,
                  content: title,
                },
                {
                  name: `twitter:description`,
                  content: metaDescription,
                },
              ]
                .concat(
                  keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `),
                      }
                    : []
                )
                .concat(meta)}
            >

            {children}

            </Helmet>
          )
        } else {
          return (
            <Helmet
              htmlAttributes={{
                lang,
              }}
              title={title}
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
  
              meta={[
                {
                  name: `description`,
                  content: metaDescription,
                },
                {
                  property: `og:title`,
                  content: title,
                },
                {
                  property: `og:description`,
                  content: metaDescription,
                },
                {
                  property: `og:type`,
                  content: `website`,
                },
                {
                  name: `twitter:creator`,
                  content: data.site.siteMetadata.author,
                },
                {
                  name: `twitter:title`,
                  content: title,
                },
                {
                  name: `twitter:description`,
                  content: metaDescription,
                },
              ]
               .concat(
                  metaImage
                  ? [
                      {
                        property: "og:image",
                        content: image.src,
                      },
                      {
                        name: "twitter:card",
                        content: "summary_large_image",
                      },
                    ]
                  : [
                      {
                        name: "twitter:card",
                        content: "summary",
                      },
                    ]
                )
                .concat(
                  keywords.length > 0
                    ? {
                        name: `keywords`,
                        content: keywords.join(`, `),
                      }
                    : []
                )
                .concat(meta)}
            />
          )          
        }

      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  children: PropTypes.object,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
